/* eslint-disable prefer-const */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, Icon, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm, VTextFieldData, VFieldMoney, BasicSelect, AutoCompleteProdutor, AutoCompleteFazenda, AutoCompleteTransportadora, AutoCompleteVeiculos, AutoCompleteParceiro, IVFormErrors } from '../../../shared/forms';
import { FerramentasDeDetalheNfe } from '../../../shared/components';
import dayjs from 'dayjs';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { AutoCompleteCfop } from '../../../shared/forms/AutoCompleteCfop';
import { NfeService } from '../../../shared/services/api/fiscal/nfe/NfeService';
import { DialoNfe } from './DialogNfe';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from '../../../shared/environment';
import { DialoCancelar } from './DialogCancelar';
import { DialogCartaCorrecao } from './DialogCartaCorrecao';



interface IFormData {
  serie: number;
  nfe: number;
  indpag: number,
  dataemissao: Date;
  finnfe: number;
  nfecpl: string;
  tpag: number;
  cfop: number;
  obscomplementar: string;
  idprodutor: number;
  idparceiro: string;
  idtransportadora?: string | null;
  volumequantidade: string;
  volumemarca: string;
  volumepesoliquido: number;
  volumepesobruto: number;
  vprod: number;
  vbc: number;
  vicms: number;
  vfrete: number;
  vseg: number;
  vdesc: number;
  vipi: number;
  vpis: number;
  vconfins: number;
  vnfe: number;
  idfazenda: string;
  nferef: string; 
  tpnfe: number;
  volesp: string;
  idveiculo?: string | null;
  modfrete: number;
}

interface IItensNFe{
  iditens: string;
  idprod: string;
  descricao: string;
  un: string;
  ncm: string;
  cfop: number;
  cst: number;
  quantidade: number;
  preco: number;
  desconto: number;
  total: number;
  frete: number;
  seguro: number;
  bcicms: number;
  percentual: number;
  valoricms: number;
  bcst: number;
  percentualst: number;
  valoricmsst: number;
  cstipi: number;
  bcipi: number;
  percentualipi: number;
  valoripi: number;
  cstpis: number;
  bcpis: number;
  percentualpis: number;
  valorpis: number;
  cstcofins: number;
  bccofins: number;
  percentualcofins: number;
  valorcofins: number;
  voutros: number;
  bcicmsst: number;
  preducaoicms: number;
}

interface IItensids{
  iditens: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  serie:  yup.number().optional().default(1), 
  nfe: yup.number().optional().default(0),
  indpag: yup.number().required(), 
  dataemissao: yup.date().required(),
  finnfe: yup.number().required(), 
  nfecpl: yup.string().optional().default(''), 
  tpag: yup.number().required(), 
  cfop: yup.number().required(), 
  obscomplementar: yup.string().optional().default(''),
  idprodutor: yup.number().required(), 
  idparceiro: yup.string().required(),
  idtransportadora: yup.string().nullable(),
  volumequantidade: yup.string().required(),
  volumemarca: yup.string().required(),
  volumepesoliquido: yup.number().required(), 
  volumepesobruto: yup.number().required(), 
  vprod: yup.number().required(), 
  vbc: yup.number().required(), 
  vicms: yup.number().required(), 
  vfrete: yup.number().required(), 
  vseg: yup.number().required(), 
  vdesc: yup.number().required(), 
  vipi: yup.number().required(), 
  vpis: yup.number().required(), 
  vconfins: yup.number().required(), 
  vnfe: yup.number().required(), 
  idfazenda: yup.string().required(),
  nferef: yup.string().optional().default(''), 
  tpnfe: yup.number().required(), 
  modfrete: yup.number().required(),  
  volesp: yup.string().required(),
  idveiculo: yup.string().nullable(),
});

export const DetalheDaNfe: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [opencancelar, setOpencancelar] = useState(false);
  const [opencarta, setOpencarta] = useState(false);
  const [statuss, setStatuss] = useState(1);
  const [value, setValue] = useState('');
  const [valueCancelar, setValueCancelar] = useState('');
  const [valueCarta, setValuecarta] = useState('');
  const [iditens, setIditens] = useState('');
  const [transportadora, setTransportadora] = useState('');
  const [cfop, setCfop] = useState('');
  const [pdf, setPdf] = useState('');
  const [protocolo, setProtocolo] = useState('');
  const [cancelar, setCancelar] = useState(1); 
  const [rows, setRows] = useState<IItensNFe[]>([]);

  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  
  const [dadositens, setDadositens] = useState<IItensNFe>();

  const guuid = uuidv4();

  
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      NfeService.getById(id)
        .then((result) => {
          setIsLoading(false);
 
          if (result instanceof Error) {
            alert(result.message);
            navigate('/nfe');
          } else {
            formRef.current?.setData(result);
            setPdf(Environment.URL_PDFNFE+result.pathpdf);
            setProtocolo(result.protocolo);
            setRows(result.itens);
            setCfop(result.cfop.toString());
            setStatuss(result.status);
            const dataObjeto = new Date(result.dataenvio);
            if (result.dataenvio !== null){
              const diferencaEmDias = calcularDiferencaEntreDatas(dataObjeto, new Date());
              if (diferencaEmDias > 24){
                setCancelar(1);
              }else{
                setCancelar(2); 
              }
            } 
          }
        });
    } else {
      formRef.current?.setData({
        tpnfe: 1,
        finnfe: 0,
        indpag: 17,
        tpag: 2,
        dataemissao: dayjs(),
        volesp: 'KG',
        obscomplementar: '',
        nferef: '',
        nfecpl: '',
        volumequantidade: 0,
        volumemarca: 'FORMOSA',
        volumepesoliquido: 0,
        volumepesobruto: 0,
        vprod: 0,
        vbc: 0,
        vicms: 0,
        vfcp: 0,
        vbcst: 0,
        vst: 0,
        vfcpst: 0,
        vfrete: 0,
        vseg: 0,
        vdesc: 0,
        vipi: 0,
        vipidevol: 0,
        vpis: 0,
        vconfins: 0,
        voutros: 0,
        vnfe: 0,
        modfrete: 5,
      });
    }  
  }, [id]);

  function calcularDiferencaEntreDatas(data1: Date, data2: Date): number {
    const data1EmMilissegundos = data1.getTime();
    const data2EmMilissegundos = data2.getTime();
    const diferencaEmMilissegundos = Math.abs(data2EmMilissegundos - data1EmMilissegundos);
    const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60);
    return diferencaEmDias;
}

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'novo') {
          NfeService
            .create(dadosValidados, rows)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/nfe');
                } else {
                  navigate(`/nfe/detalhe/${result}`);
                }
              }
            });
        } else {
          NfeService
            .updateById(id, dadosValidados, rows, rowsids)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/nfe');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {

      let meuObjeto = {iditens: id};
     
      rowsids.push(meuObjeto); 
      
     const indexToDelete = rows.findIndex((item) => item.iditens === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);

        const totais = calcularTotais(updatedList);
        const tvprod = totais.tvprod;
        const tbcicms = totais.tbcicms;
        const tvicms = totais.tvicms;
        const tconfins = totais.tconfins;
        const tpis = totais.tpis;
        const tipi = totais.tipi;
        const tfrete = totais.tfrete;
        const tseguro = totais.tseguro;
        const tdesconto = totais.tdesconto;
        const tnfe = totais.tvprod + totais.tfrete + totais.tseguro + totais.tipi + totais.tconfins + totais.toutros - totais.tdesconto;
        Object.entries(totais).forEach(([campo, valor]) => {
          formRef.current?.setFieldValue(campo, valor);
        });
        formRef.current?.setFieldValue('vnfe', tnfe);
        formRef.current?.setFieldValue('vprod', tvprod);
        formRef.current?.setFieldValue('vconfins', tconfins);
        formRef.current?.setFieldValue('vpis', tpis);
        formRef.current?.setFieldValue('vipi', tipi);
        formRef.current?.setFieldValue('vfrete', tfrete);
        formRef.current?.setFieldValue('vseg', tseguro);
        formRef.current?.setFieldValue('vdesc', tdesconto);
        formRef.current?.setFieldValue('vprod', tvprod);
        formRef.current?.setFieldValue('vicms', tvicms);
        formRef.current?.setFieldValue('vnfe', tnfe);
        formRef.current?.setFieldValue('vbc', tbcicms);
      }
    }
  };

  const handleBuscarDados = (id: string) => {
    const updatedList = rows.map((listItem) => {
      if (listItem.iditens === id) {
        setDadositens(listItem);
      }
    });
  };

  const handleuuidtransportadora = (uuid: string | undefined) => {
    if (uuid != null) {
      setTransportadora(uuid);
    }
  };
   
  const handlecfop = (cfops: number | undefined) => {
    if (cfops != null) {
      if (cfops > 0){
        setCfop(cfops.toString());
      }   
    }
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  const handleClosecancelar = (newValue?: string) => {
    setOpencancelar(false);

    if (newValue) {
      setValueCancelar(newValue);
    }
  };

  const handleClosecarta = (newValue?: string) => {
    setOpencarta(false);

    if (newValue) {
      setValuecarta(newValue);
    }
  };

  type RowsType = IItensNFe[];

  const calcularTotais = (lista: RowsType) => {
    const tvprod = lista.reduce((acc, item) => acc + item.total, 0);
    const tbcicms = lista.reduce((acc, item) => acc + item.bcicms, 0);
    const tvicms = lista.reduce((acc, item) => acc + item.valoricms, 0);
    const tconfins = lista.reduce((acc, item) => acc + item.valorcofins, 0);
    const tpis = lista.reduce((acc, item) => acc + item.valorpis, 0);
    const tipi = lista.reduce((acc, item) => acc + item.valoripi, 0);
    const tfrete = lista.reduce((acc, item) => acc + item.frete, 0);
    const tseguro = lista.reduce((acc, item) => acc + item.seguro, 0);
    const tdesconto = lista.reduce((acc, item) => acc + item.desconto, 0);
    const toutros = lista.reduce((acc, item) => acc + item.voutros, 0);
  
    return {
      tvprod,
      tbcicms,
      tvicms,
      tconfins,
      tpis,
      tipi,
      tfrete,
      tseguro,
      tdesconto,
      toutros,
    };
  };
  
  const handleItens = (dados: IItensNFe) => {
    const index = rows.findIndex((listItem) => listItem.iditens === dados.iditens);
  
    let updatedList: RowsType;
  
    if (index !== -1) {
      updatedList = [...rows];
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);
    const totais = calcularTotais(updatedList);
    const tvprod = totais.tvprod;
    const tbcicms = totais.tbcicms;
    const tvicms = totais.tvicms;
    const tconfins = totais.tconfins;
    const tpis = totais.tpis;
    const tipi = totais.tipi;
    const tfrete = totais.tfrete;
    const tseguro = totais.tseguro;
    const tdesconto = totais.tdesconto;
    const tnfe = totais.tvprod + totais.tfrete + totais.tseguro + totais.tipi + totais.tconfins + totais.toutros + dados.valoricmsst - totais.tdesconto;
    Object.entries(totais).forEach(([campo, valor]) => {
      formRef.current?.setFieldValue(campo, valor);
    });




    formRef.current?.setFieldValue('vnfe', tnfe);
    formRef.current?.setFieldValue('vprod', tvprod);
    formRef.current?.setFieldValue('vconfins', tconfins);
    formRef.current?.setFieldValue('vpis', tpis);
    formRef.current?.setFieldValue('vipi', tipi);
    formRef.current?.setFieldValue('vfrete', tfrete);
    formRef.current?.setFieldValue('vseg', tseguro);
    formRef.current?.setFieldValue('vdesc', tdesconto);
    formRef.current?.setFieldValue('vprod', tvprod);
    formRef.current?.setFieldValue('vicms', tvicms);
    formRef.current?.setFieldValue('vnfe', tnfe);
    formRef.current?.setFieldValue('vbc', tbcicms);
  };

  const handlePrint = () => {
    const pdfUrl: string = pdf;
    window.open(pdfUrl, '_blank');
  };

  const handleTransmitir = () => {
    setIsLoading(true);
    NfeService.transmitir(id)
     .then((result) => {
      setIsLoading(false);
      if (result instanceof Error) {
        alert(result.message);
      } else {
         window.open(Environment.URL_PDFNFE+result, '_blank');
      }
    });
  };

  const handleAddProtocolo = () => {
    setIsLoading(true);
    NfeService.addprotocolo(id)
     .then((result) => {
      setIsLoading(false);
      if (result instanceof Error) {
        alert(result.message);
      } else {
         window.open(Environment.URL_PDFNFE+result, '_blank');
      }
    });
  };

  const handleCancelar = () => {
    setOpencancelar(true);
  };

  const handleCarta = () => {
    setOpencarta(true);
  };
  
  return (
    <LayoutBaseDePaginaNfe
       barraDeFerramentas={
        <FerramentasDeDetalheNfe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao={id !== 'novo'}
          mostrarBotaoCancelar={id !== 'novo'}
          mostrarBotaoCarta={id !== 'novo'}
          mostrarBotaoProtocolo={id !== 'novo'}
          mostrarBotaoTransmitir={id !== 'novo'}
          mostrarBotaoApagarCarregando={false}
          mostrarBotaoImpressaoCarregando={isLoading}
          disableBotaoSalvar={statuss === 1 ? isLoading : true}
          disableBotaoImpressao={statuss === 2 ? isLoading : true}
          disableBotaoCancelar={cancelar === 2 ? isLoading : true}
          disableBotaoTransmitir={statuss === 1 ? isLoading : true}
          disableBotaoProtocolo={protocolo === null ? isLoading : true}
          disableBotaoSalvarEFechar={statuss === 1 ? isLoading : true}
          disableBotaoCarta={cancelar === 2 ? isLoading : true}

          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmTransmitir={handleTransmitir}
          aoClicarEmCancelar={handleCancelar}
          aoClicarEmCarta={handleCarta}
          aoClicarEmProtocolo={handleAddProtocolo}
          aoClicarEmVoltar={() => navigate('/nfe')}
          mostrarBotaoVoltarCarregando={isLoading}
          mostrarBotaoTransmitirCarregando={isLoading}
          aoClicarEmNovo={() => navigate('/nfe/detalhe/novo')}
          aoClicarEmImpressao={() => handlePrint()}
          mostrarBotaoNovoCarregando={isLoading}
        />
      }
    >    

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row"  spacing={2}>
            
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <AutoCompleteParceiro
                      isExternalLoading={statuss === 1 ? isLoading : true}
                      cfops={handlecfop}
                    />  
                  </Grid>                  
                  <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
                    <AutoCompleteProdutor
                      isExternalLoading={statuss === 1 ? isLoading : true}
                    />                 
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                    <AutoCompleteFazenda
                      isExternalLoading={statuss === 1 ? isLoading : true}
                    />     
                  </Grid>                 
                  <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='dataemissao'
                      label='Data Emissão'
                      disabled={statuss === 1 ? isLoading : true}
                    />
                  </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                 <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <BasicSelect
                          name='tpnfe' 
                          label='Tipo'
                          data={[
                            { nameBD: '0', descricao: 'Entrada' },
                            { nameBD: '1', descricao: 'Saida' },
                          ]}
                          disabled={statuss === 1 ? isLoading : true}
                    />
                  </Grid>                  
                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <VTextField
                      fullWidth
                      name='serie'
                      label='Serie'
                      disabled={true}
                    />               
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <VTextField
                      fullWidth
                      name='nfe'
                      label='Numero'
                      disabled={true}
                    />               
                  </Grid>                 
                  <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <AutoCompleteCfop
                       isExternalLoading={statuss === 1 ? isLoading : true}
                       cfops={cfop}
                     />
                  </Grid>      
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <BasicSelect
                          name='finnfe'
                          label='Finalidade'
                          data={[
                            { nameBD: '0', descricao: 'Normal' },
                            { nameBD: '1', descricao: 'Complementar' },
                            { nameBD: '2', descricao: 'Ajuste' },
                            { nameBD: '3', descricao: 'Devolução' },
                          ]}
                          disabled={statuss === 1 ? isLoading : true}
                    />
                  </Grid>     
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <BasicSelect
                          name='indpag'
                          label='Pagamento'
                          data={[
                            { nameBD: '0', descricao: 'Dinheiro' },
                            { nameBD: '1', descricao: 'Cheque' },
                            { nameBD: '2', descricao: 'Cartao Credito' },
                            { nameBD: '3', descricao: 'Cartao Debito' },
                            { nameBD: '4', descricao: 'Credito Loja' },
                            { nameBD: '5', descricao: 'Vale Alimentacao' },
                            { nameBD: '6', descricao: 'Vale Refeicao' },
                            { nameBD: '7', descricao: 'Vale Presente' },
                            { nameBD: '8', descricao: 'Vale Combustivel' },
                            { nameBD: '9', descricao: 'Duplicata Mercantil' },
                            { nameBD: '10', descricao: 'Boleto Bancario' },
                            { nameBD: '11', descricao: 'Deposito Bancario' },
                            { nameBD: '12', descricao: 'Pagamento Instantaneo' },
                            { nameBD: '13', descricao: 'TransfBancario' },
                            { nameBD: '14', descricao: 'Programa Fidelidade' },
                            { nameBD: '15', descricao: 'Sem Pagamento' },
                            { nameBD: '16', descricao: 'Regime Especial' },
                            { nameBD: '17', descricao: 'Outro' },
                          ]}
                          disabled={statuss === 1 ? isLoading : true}
                    />
                  </Grid>     
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <BasicSelect
                          name='tpag'
                          label='Tipo Pagamento'
                          data={[
                            { nameBD: '0', descricao: 'Vista' },
                            { nameBD: '1', descricao: 'Prazo' },
                            { nameBD: '2', descricao: 'Outras' },
                            { nameBD: '3', descricao: 'Nenhum' },
                          ]}
                          disabled={statuss === 1 ? isLoading : true}
                    />
                  </Grid>     
                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='vbc'
                      label='Base C. ICMS'
                      disabled={true}
                    />
                  </Grid>                  
                  
                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='vicms'
                      label='Valor ICMS'
                      disabled={true}
                    />
                  </Grid>      

                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='vconfins'
                      label='COFINS'
                      disabled={true}
                    />
                  </Grid> 

                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='vpis'
                      label='PIS'
                      disabled={true}
                    />
                  </Grid> 

                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='vipi'
                      label='Valor IPI'
                      disabled={true}
                    />
                  </Grid> 

                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='vfrete'
                      label='Valor Frete'
                      disabled={true}
                    />
                  </Grid> 

                  <Grid item xs={12} sm={2} md={2} lg={1} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='vseg'
                      label='Valor Seguro'
                      disabled={true}
                    />
                  </Grid> 


                  <Grid item xs={12} sm={2} md={2} lg={1} xl={1}>
                    <VFieldMoney
                      fullWidth
                      name='vdesc'
                      label='desconto'
                      disabled={true}
                    />
                  </Grid> 

                  <Grid item xs={12} sm={2} md={2} lg={1} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='vprod'
                      label='Valor dos Produtos'
                      disabled={true}
                    />
                  </Grid> 

                  <Grid item xs={12} sm={2} md={2} lg={3} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='vnfe'
                      label='Valor Nota'
                      disabled={true}
                    />
                  </Grid> 

              </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                  <AutoCompleteTransportadora
                      isExternalLoading={statuss === 1 ? isLoading : true}
                      uuidtransportadora={handleuuidtransportadora}
                    />   
                  </Grid>                  
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
                  <AutoCompleteVeiculos
                      isExternalLoading={statuss === 1 ? isLoading : true}
                      idtransportadora={transportadora}
                    />   
                  </Grid>               
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={1}>
                  <BasicSelect
                          name='modfrete'
                          label='Tipo Frete'
                          data={[
                            { nameBD: '0', descricao: 'Conta Emitente' },
                            { nameBD: '1', descricao: 'Conta Destinatario' },
                            { nameBD: '2', descricao: 'Conta Terceiros' },
                            { nameBD: '3', descricao: 'Proprio Remetente' },
                            { nameBD: '4', descricao: 'Proprio Destinatario' },
                            { nameBD: '5', descricao: 'Sem Frete' },
                          ]}
                          disabled={statuss === 1 ? isLoading : true}
                    />           
                  </Grid>
                          
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={1}>
                    <VTextField
                      fullWidth
                      name='volumequantidade'
                      label='Quantidade'
                      disabled={statuss === 1 ? isLoading : true}
                    />      
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={1}>
                    <VTextField
                      fullWidth
                      name='volesp'
                      label='Espécie'
                      disabled={statuss === 1 ? isLoading : true}
                    />      
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      name='volumemarca'
                      label='Marca'
                      disabled={statuss === 1 ? isLoading : true}
                    />      
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={1}>
                    <VTextField
                      fullWidth 
                      name='volumepesobruto'
                      label='Peso Bruto'
                      disabled={statuss === 1 ? isLoading : true}
                    />      
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={1}>
                    <VTextField
                      fullWidth
                      name='volumepesoliquido'
                      label='Peso Liquido'
                      disabled={statuss === 1 ? isLoading : true}
                    />      
                  </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined" sx={{width: 'auto', height:350, maxHeight:350 }}>
                  <Table size="small">
                    <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                      <TableRow >
                        <TableCell style={{ width: 20, maxWidth:10, color: '#fff' }} align='right'  >Quantidade</TableCell>
                        <TableCell style={{ width: 140, color: '#fff' }} align='left'>Descrição</TableCell>
                        <TableCell style={{ width: 10, color: '#fff' }}>UN</TableCell>
                        <TableCell style={{ width: 10, color: '#fff' }} align='center'>NCM</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='center'>CFOP</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='right'>valor Unitario</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='right'>Desconto</TableCell>
                        <TableCell style={{ width: 10, color: '#fff' }} align='right'>valor total</TableCell>
                        <TableCell style={{ width: 2, maxWidth:'1px', maxHeight: '10px',  color: '#fff' }} align='center'>
                        <IconButton color='inherit' disabled={statuss === 1 ? isLoading : true}  aria-label="add to shopping cart" size="large">
                                <AddShoppingCartIcon  onClick={() => {setIditens('novo');  setOpen(true);}} />
                           </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.iditens} 
                          hover
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell style={{ width: 20 }} align='right' >{row.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 340 }} >{row.descricao}</TableCell>
                          <TableCell style={{ width: 10 }} >{row.un}</TableCell>
                          <TableCell style={{ width: 10 }} align='center'>{row.ncm}</TableCell>
                          <TableCell style={{ width: 40 }} align='center'>{row.cfop}</TableCell>
                          <TableCell style={{ width: 40 }} align='right'>{row.preco.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 40 }} align='right'>{row.desconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 10 }} align='right'>{row.total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                          <TableCell>
                             <IconButton size="small" onClick={() => {setIditens(row.iditens); handleBuscarDados(row.iditens);  setOpen(true);}}>
                               <Icon >edit</Icon>
                              </IconButton>
                            <IconButton disabled={statuss === 1 ? isLoading : true}  size="small" onClick={() => {handleDelete(row.iditens);}}>
                              <Icon>delete</Icon>
                             </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={2} >
                  <Grid item  xs={12} sm={12} md={12} lg={6} xl={6}>
                    <VTextField
                      fullWidth
                      name='obscomplementar'
                      label='Informaçoes Adicionais'
                      multiline
                      rows={4}
                      disabled={statuss === 1 ? isLoading : true}
                    />
                  </Grid>         

                  <Grid item  xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Grid container item direction="row" spacing={1} >
                      <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          name='nfecpl'
                          label='Chave da Nota de Devolução'
                          disabled={statuss === 1 ? isLoading : true}
                        />       
                      </Grid>
                       <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          name='nferef'
                          label='Chave da Nota Referenciada'
                          disabled={statuss === 1 ? isLoading : true}
                        />
                      </Grid>
                  </Grid>
                  </Grid>
              </Grid>
              </Grid>
          </Grid>
          <DialoNfe 
              id={iditens}
              value={guuid}
              cfop={Number(cfop)}
              dados={dadositens}
              open={open} 
              onDados={handleItens}
              onClose={handleClose}
              status={statuss}
            /> 
           <DialoCancelar 
              id={id}
              open={opencancelar} 
              onClose={handleClosecancelar}
              status={statuss}
            /> 
            <DialogCartaCorrecao
              id={id}
              open={opencarta} 
              onClose={handleClosecarta}
              status={statuss}
            /> 
          </Grid>
        </Box>
      </VForm>

    </LayoutBaseDePaginaNfe>
  );
};